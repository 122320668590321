import React, { useEffect, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { authenticatedUser } from 'header-scripts';
import { AccountSwitcherService } from 'Roblox';
import { loginTranslationConfig } from '../translation.config';
import { FeatureLoginPage } from '../../common/constants/translationConstants';
import { buildSignupRedirUrl } from '../../common/utils/browserUtils';
import { getAccountSwitchingSignupUrl } from '../utils/loginUtils';

export const SignupLink = ({ translate }: WithTranslationsProps): JSX.Element => {
  const isAccountSwitching = AccountSwitcherService && authenticatedUser.isAuthenticated;
  const signupRedirUrl = isAccountSwitching
    ? getAccountSwitchingSignupUrl()
    : buildSignupRedirUrl();

  return (
    <div className='text-center'>
      <div className='signup-option'>
        {!isAccountSwitching && (
          <span className='no-account-text'>{translate(FeatureLoginPage.LabelNoAccount)}</span>
        )}
        <a id='sign-up-link' className='text-link signup-link' href={signupRedirUrl} target='_self'>
          {translate(
            isAccountSwitching
              ? FeatureLoginPage.ActionCreateANewAccount
              : FeatureLoginPage.ActionSignUpCapitalized
          )}
        </a>
      </div>
    </div>
  );
};

export default withTranslations(SignupLink, loginTranslationConfig);
