import { EnvironmentUrls, Endpoints } from 'Roblox';

const { getAbsoluteUrl } = Endpoints;

export const urlConstants = {
  securityNotification: `${EnvironmentUrls.websiteUrl}/login/securityNotification`,
  koreaIdVerification: `${EnvironmentUrls.websiteUrl}/id-verification/korea/login`,
  forgotCredentialsUrl: `${EnvironmentUrls.websiteUrl}/login/forgot-password-or-username`
};

export const counterConstants = {
  prefix: 'WebsiteLogin_',
  firstAttempt: 'FirstAttempt',
  attempt: 'Attempt',
  success: 'Success',
  captcha: 'Captcha',
  passwordResetRequired: 'PasswordResetRequired',
  unverifiedAccount: 'UnverifiedAccount',
  invalidCredentials: 'InvalidCredentials',
  accountNotFound: 'AccountNotFound',
  noPassword: 'NoPassword',
  accountIssue: 'AccountIssue',
  tooManyAttempts: 'TooManyAttempts',
  defaultLoginRequired: 'DefaultLoginRequired',
  redirectToLogin: 'RedirectToLogin',
  captchaLoadFailed: 'CaptchaLoadFailed',
  captchaVerifyFailed: 'CaptchaVerifyFailed',
  captchaUnknownError: 'CaptchaUnknownError',
  luoBuUserDenied: 'LuoBuUserDenied',
  screentimeRestricted: 'ScreenTimeRestricted',
  unknownError: 'UnknownError'
};

export const containerConstants = {
  react2svContainer: 'react-2sv-container',
  reactCaptchaContainer: 'react-captcha-container',
  reactSecurityQuestionsContainer: 'react-security-questions-container',
  reactAccountSelectorContainer: 'react-account-selector-container',
  otpLoginContainer: 'otp-login-container',
  reactLoginAccountSwitcherContainer: 'react-login-account-switcher-container',
  reactAccountLimitErrorContainer: 'react-login-account-limit-error-container',
  accountSwitcherConfirmationModalContainer: 'account-switcher-confirmation-modal-container'
};

export const errorCodes = {
  unknownError: 0,
  badCredentials: 1,
  captcha: 2,
  accountNotFound: 3,
  passwordResetRequired: 4,
  noPassword: 5,
  accountIssue: 6,
  tooManyAttempts: 7,
  defaultLoginRequired: 9,
  unverifiedCredentials: 10,
  captchaLoadFailed: 11,
  captchaVerifyFailed: 12,
  captchaUnknownError: 13,
  luoBuUserDenied: 14,
  screentimeRestricted: 16,
  securityQuestionRequired: 18,
  securityQuestionFailed: 19,
  multipleUsersPerCredential: 20,
  credentialsNotAllowed: 22,
  loginBlocked: 23,
  emptyAccountSwitchBlobRequired: 24,
  maxLoggedInAccountsLimitReached: 25,
  parentEmptyAccountSwitchBlobRequired: 26
};

export const retryAttempts = {
  maxInvalidated2svChallengeAttempts: 3
};

export const eventCounters = {
  prefix: 'WebsiteLogin_',
  firstAttempt: 'FirstAttempt',
  attempt: 'Attempt',
  successWithSAI: 'SuccessWithSAI',
  success: 'Success',
  captcha: 'Captcha',
  passwordResetRequired: 'PasswordResetRequired',
  unverifiedAccount: 'UnverifiedAccount',
  invalidCredentials: 'InvalidCredentials',
  accountNotFound: 'AccountNotFound',
  noPassword: 'NoPassword',
  accountIssue: 'AccountIssue',
  tooManyAttempts: 'TooManyAttempts',
  defaultLoginRequired: 'DefaultLoginRequired',
  redirectToLogin: 'RedirectToLogin',
  captchaLoadFailed: 'CaptchaLoadFailed',
  captchaVerifyFailed: 'CaptchaVerifyFailed',
  captchaUnknownError: 'CaptchaUnknownError',
  luoBuUserDenied: 'LuoBuUserDenied',
  screentimeRestricted: 'ScreenTimeRestricted',
  unknownError: 'UnknownError',
  securityQuestionRequired: 'SecurityQuestionRequired'
};

export const isNewLoginQueryString = 'nl=true';

export const otpOrigin = 'login';

export const experimentLayer = 'Website.Login';

// Absolute url for signup in account switching
export const accountSwitchingSignupUrl = getAbsoluteUrl('/CreateAccount');
