import { eventStreamService } from 'core-roblox-utilities';
import EVENT_CONSTANTS from '../constants/eventsConstants';

export const sendSAISuccessEvent = (): void => {
  eventStreamService.sendEventWithTarget(
    EVENT_CONSTANTS.eventName.saiCreated,
    EVENT_CONSTANTS.context.hba,
    {}
  );
};

export const sendSAIMissingEvent = (): void => {
  eventStreamService.sendEventWithTarget(
    EVENT_CONSTANTS.eventName.saiMissing,
    EVENT_CONSTANTS.context.hba,
    {}
  );
};

export default {
  sendSAISuccessEvent,
  sendSAIMissingEvent
};
